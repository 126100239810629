import { FormKitConfig } from '@formkit/core'
import { Language } from '@shopware-pwa/types'

export const useShopLanguages = () => {
  const { refreshSessionContext, sessionContext } = useSessionContext()
  const { getAvailableLanguages, changeLanguage } = useInternationalization()
  const { apiInstance } = useShopwareContext()
  const { data } = useNuxtData('languages')

  const formkitConfig = inject(Symbol.for('FormKitConfig')) as FormKitConfig

  const languages = computed(() => data.value?.elements as Language[])

  const getLanguageByCode = async (code: string) => {
    if (languages.value) {
      return languages.value.find((lang) => lang.translationCode?.code.split('-')[0] === code)!
    }

    const { data } = await useAsyncData('languages', async () => {
      return await getAvailableLanguages()
    })

    return (data.value?.elements as Language[]).find((lang) => lang.translationCode?.code.split('-')[0] === code)!
  }

  const changeShopLanguage = async (code: string) => {
    const language = await getLanguageByCode(code)
    // change language in shopware
    await changeLanguage(language.id)
    apiInstance.update({ languageId: language.id })
    await refreshSessionContext()

    formkitConfig.locale = code

    return language.id
  }

  return {
    getCurrentShopLanguage: () => sessionContext.value?.context.languageIdChain[0],
    getLanguageByCode,
    changeShopLanguage,
  }
}
